#appointment-app {
  position: relative;

  .loader {
    display: none;
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($light, .75);

    &.is-active {
      display: block;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 200px;
    }
  }

  .appointment-error-popup {
    content: '';
    position: absolute;
    z-index: 4;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .popup-overlay {
      position: absolute;
      inset: 0;
      background: rgba(236, 236, 236, 0.9);
      backdrop-filter: blur(4px);
    }

    .popup-container {
      position: absolute;
      top: calc(50vh - 100px);
      left: 50%;
      transform: translate(-50%, -50%);
      background: #FFFFFF;
      border-radius: 16px;
      padding: 100px 45px 40px;
      width: 360px;
      max-width: calc(100vw - 32px);
      display: flex;
      flex-direction: column;
      align-items: center;

      .popup-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 80px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PHBhdGggZD0iTTE3LjE5LjQwM2ExLjMyOCAxLjMyOCAwIDAgMC0xLjg4IDBMOC43OSA2LjkxIDIuMjcuMzlhMS4zMjggMS4zMjggMCAwIDAtMS44OCAwYy0uNTIuNTItLjUyIDEuMzYgMCAxLjg4bDYuNTIgNi41Mi02LjUyIDYuNTJjLS41Mi41Mi0uNTIgMS4zNiAwIDEuODguNTIuNTIgMS4zNi41MiAxLjg4IDBsNi41Mi02LjUyIDYuNTIgNi41MmMuNTIuNTIgMS4zNi41MiAxLjg4IDAgLjUyLS41Mi41Mi0xLjM2IDAtMS44OGwtNi41Mi02LjUyIDYuNTItNi41MmExLjMzNiAxLjMzNiAwIDAgMCAwLTEuODY3WiIgZmlsbD0iIzczODc5QSIvPjwvc3ZnPg==");
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
      }

      .popup-heading {
        font-family: $fontNexa;
        font-style: normal;
        font-weight: 900;
        font-size: 22px;
        line-height: 120%;
        text-align: center;
        color: $dark;
        margin: 0 0 23px;
      }

      .popup-text {
        font-family: $fontNexa;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 170%;
        text-align: center;
        color: $dark;
        margin: 0 0 55px;
      }

      .popup-button {
        @extend .button;
        @extend .blue;
      }
    }
  }
}

.form-process {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light;
  padding: 80px 0;

  @media (max-width: 1023px) {
    padding: 20px 0;
  }

  .form-process-back-link {
    @extend .link-with-arrow;
    @extend .is-reversed;
    margin-top: 32px;
  }

  .form-process-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .process-heading {
      font-family: $fontNexa;
      font-style: normal;
      font-weight: bold;
      font-size: 21px;
      line-height: 130%;
      text-align: center;
      color: $colorAccent1;
      width: 100%;
      max-width: 475px;
      margin: 0 0 60px;

      @media(max-width: 767px) {
        font-size: 18px;
        margin-bottom: 30px;
      }
    }

    .form-header {
      width: 100%;
      max-width: 320px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 26px;

      &-block {
        width: 320px;
      }

      @media(max-width: 520px) {
        max-width: 100%;
      }

      .top-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .header-number {
          width: 44px;
          height: 44px;
          text-align: center;
          background-color: $colorAccent1;
          color: $light;
          @extend .titleBold;
          line-height: 44px;
          border-radius: 44px;
        }

        .header-infos {
          margin-left: 8px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .header-caption {
            font-weight: 800;
            text-transform: uppercase;
            color: #D6D8E7;
            margin: 0;

            span {
              color: $colorAccent1;
            }
          }

          .header-heading {
            font-weight: 800;
            margin: -5px 0 0;
          }
        }
      }

      .bottom-header {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .steps-line {
          content: '';
          position: absolute;
          width: calc(100% - 12px);
          left: 6px;
          right: 6px;
          top: 50%;
          height: 3px;
          transform: translateY(-50%);
          background-color: #D6D8E7;

          .step-progress {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 0;
            background-color: $colorAccent1;
          }
        }

        .step {
          position: relative;
          z-index: 1;
          width: 12px;
          height: 12px;
          border-radius: 12px;
          background-color: #D6D8E7;
        }

        &.step-1 {
          .step:first-child {
            background-color: $colorAccent1;
          }
        }

        &.step-2 {
          @extend .step-1;

          .step:nth-child(2) {
            background-color: $colorAccent1;
          }

          .steps-line .step-progress {
            width: calc(100% / 4);
          }

          &.five-step {
            .steps-line .step-progress {
              width: calc(100% / 4);
            }
          }
        }

        &.step-2_5 {
          @extend .step-2;

          .steps-line .step-progress {
            width: calc((100% / 4) * 1.5);
          }
        }

        &.step-3 {
          @extend .step-2_5;

          .step:nth-child(3) {
            background-color: $colorAccent1;
          }

          .steps-line .step-progress {
            width: calc((100% / 4) * 2);
          }

          &.five-step {
            .steps-line .step-progress {
              width: calc((100% / 4) * 2);
            }
          }
        }

        &.step-4 {
          @extend .step-3;

          .step:nth-child(4) {
            background-color: $colorAccent1;
          }

          .steps-line .step-progress {
            width: calc((100% / 4) * 3);
          }

          &.five-step {
            .steps-line .step-progress {
              width: calc((100% / 4) * 3);
            }
          }
        }

        &.step-5 {
          @extend .step-4;

          .step:nth-child(5) {
            background-color: $colorAccent1;
          }

          .steps-line .step-progress {
            width: calc((100% / 4) * 4);
          }

          &.five-step {
            .steps-line .step-progress {
              width: calc((100% / 4) * 4);
            }
          }
        }

        &.step-6 {
          @extend .step-5;

          .step:nth-child(6) {
            background-color: $colorAccent1;
          }

          .steps-line .step-progress {
            width: 100%;
          }
        }
      }
    }

    .form-process-heading,
    .form-process-bottom-text {
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 20px;
      width: 100%;
      max-width: 645px;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 20px;
      }
     }

    .form-process-bottom-text {
      font-size: 16px;
      color: $colorAccent1;
    }

    .step-form-container {
      width: 100%;
      max-width: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.step2-container {
        max-width: 400px;

        .form-select {
          max-width: 380px;
        }

        .step-input-container {
          align-items: center;
        }

        .custom-step-container {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }

      &.fullwidth {
        max-width: 100%;
      }

      .step-extra-info {
        width: 100%;
        display: flex;
        flex-direction: column;

        .extra-info-heading {
          padding-top: 24px;
          border-top: 1px solid #D6D8E7;
          margin: 0 0 20px;
          width: 100%;
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 170%;
          color: $lightText;
        }

        .extra-checkbox-container {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          cursor: pointer;

          input[type="radio"] {
            display: none;

            &:checked + span {
              border-color: $colorAccent1;

              &:before {
                opacity: 1;
              }
            }
          }

          span {
            position: relative;
            width: 22px;
            height: 22px;
            border-radius: 22px;
            border: 1px solid #F4F8FF;
            background: #F4F8FF;
            margin-right: 8px;
            transition: border-color ease-out .2s;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: $colorAccent1;
              opacity: 0;
              transition: opacity ease-out .2s;
            }
          }

          .form-radio-label {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 180%;
            letter-spacing: 0.75px;
            color: $dark;
          }
        }
      }

      .form-button {
        @extend .button;
        @extend .blue;
        margin-top: 8px;
        border: none;
      }

      .step-input-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 24px;

        .form-error {
          @extend .caption;
          color: $colorError;
          margin-top: 5px;
        }

        .step-input-separator {
          width: 100%;
          border-top: 1px solid #D6D8E7;
          margin-bottom: 24px;
        }

        .form-checkbox {
          display: none;

          & + .form-checkbox-label {
            position: relative;
            padding-left: 40px;
            font-weight: 500;
            cursor: pointer;

            &:before {
              content: '';
              position: absolute;
              top: -2px;
              left: 0;
              width: 28px;
              height: 28px;
              border-radius: 32px;
              border: 2px solid $colorAccent1;
              transition: background ease-out .2s;
              background-position: center center;
              background-size: 12px;
              background-repeat: no-repeat;
            }
          }

          &:checked + .form-checkbox-label:before {
            background-color: $colorAccent1;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxMSI+PHBhdGggZD0iTTEzIDFsLTguMjUgOUwxIDUuOTEiIHN0cm9rZT0iI0Y3RjdGQyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=");
          }
        }

        .form-radio {
          display: none;

          & + .form-radio-label {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 66px;
            background: #FCFCFC;
            border: 1px solid #EFF0F7;
            box-sizing: border-box;
            box-shadow: 0 8px 16px rgba(17, 17, 17, 0.04);
            border-radius: 15px;
            padding: 16px 30px 16px 52px;
            font-weight: 800;
            cursor: pointer;
            transition: opacity ease-out .2s;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 16px;
              transform: translateY(-50%);
              width: 16px;
              height: 16px;
              border: 2px solid #D6D8E7;
              border-radius: 20px;
              transition: opacity ease-out .2s;
            }

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 21px;
              transform: translateY(-50%);
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: $colorAccent1;
              opacity: 0;
              transition: opacity ease-out .2s;
            }
          }

          &:checked + .form-radio-label {
            border-color: $colorAccent1;

            &:before {
              border-color: $colorAccent1;
            }

            &:after {
              opacity: 1;
            }
          }
        }

        .tooltip-container {
          top: -50%;
          width: 18px;
          height: 18px;
          position: relative;
          left: calc(100% - 27px);

          svg{
            cursor: pointer;

            &:hover + .tooltip-text {
              opacity: 1;
              visibility: visible;
            }
          }

          .tooltip-text {
            bottom: 0;
            opacity: 0;
            z-index: 1;
            width: 250px;
            padding: 10px;
            color: #000000;
            font-size: 12px;
            text-align: left;
            font-weight: bold;
            line-height: 16px;
            position: absolute;
            visibility: hidden;
            border-radius: 6px;
            left: calc(100% + 25px);
            transition: opacity 0.3s;
            background-color: #ffcb07;

            @media only screen and (max-width: 1440px) {
              left: -240px;
              bottom: calc(100% + 5px);
            }
          }
        }
      }

      .map-list-container {
        margin-bottom: 32px;
      }

      .form-appointment {
        width: 100%;
        max-width: 540px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .form-appointment-subtitle {
          color: $lightText;
          width: 100%;
          text-align: center;
          margin-bottom: 16px;
          font-weight: 800;
        }

        .clinic-item {
          background: #FCFCFC;
          border: 1px solid #EFF0F7;
          box-shadow: 0 8px 16px rgba(17, 17, 17, 0.04);
          border-radius: 15px;
        }

        .form-choose-specialist {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border-top: 1px solid #D6D8E7;
          padding-top: 24px;
          margin: 24px 0;

          .choose-specialist-heading {
            color: $lightText;
            font-weight: 800;
            margin-bottom: 8px;
          }

          .form-select {
            width: 100%;
          }
        }

        .form-next-appointment {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 24px;

          .next-appointment-heading {
            color: $lightText;
            font-weight: 800;
            margin-bottom: 16px;
          }

          .next-appointment-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            .next-appointment-item {
              font-weight: 800;
              padding: 12px 20px;
              border: 2px solid #EFF0F7;
              border-radius: 16px;
              margin: 0 8px 8px 0;
              transition: all ease-out .2s;
              cursor: pointer;
              background-color: $light;

              &:hover {
                box-shadow: 0 8px 16px rgba(17, 17, 17, 0.04);
              }

              &.is-active {
                border-color: $colorAccent1;
                box-shadow: 0 8px 16px rgba(17, 17, 17, 0.04);
              }
            }
          }
        }

        .form-next-dates {
          width: 100%;
          margin-bottom: 40px;
          padding: 0 70px;
          height: 82px;

          .form-next-dates-list {
            width: 100%;
            height: 100%;
            display: flex;
          }

          .flickity-viewport {
            width: 100%;
            height: 100% !important;
            overflow-y: visible;
          }

          .flickity-button {
            top: 50%;
            transform: translateY(-50%);
            width: 44px;
            height: 44px;
            border-radius: 44px;
            background-color: $colorAccent2;
            background-position: center center;
            background-size: 10px;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMSA4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTS42MTQgMy41aDguMDg1TDYuMjEuN2wuNjIyLS43IDMuNTU0IDQtMy41NTQgNC0uNjIyLS43TDguNyA0LjVILjYxNHYtMXoiIGZpbGw9IiNGNUY5RkMiLz48L3N2Zz4=");

            &:before {
              display: none;
            }

            &.previous {
              left: -70px;
              transform: translateY(-50%) rotate(180deg);
            }

            &.next {
              right: -70px;
            }
          }

          .next-date-item {
            width: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $light;
            border: 2px solid #EFF0F7;
            box-shadow: 0 8px 16px rgba(17, 17, 17, 0.04);
            border-radius: 9px;
            padding: 16px 8px 8px;
            margin-right: 8px;
            cursor: pointer;

            span:first-child {
              font-weight: 800;
            }

            span:last-child {
              color: $lightText;
              font-weight: 800;
            }

            &.is-active {
              border-color: $colorAccent1;
            }
          }
        }
      }

      .recap-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;

        @media (max-width: 1023px) {
          flex-direction: column;
        }

        .recap-card {
          width: calc(50% - 16px);
          padding: 24px;
          background: $grey;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          position: relative;

          @media (max-width: 1023px) {
            width: 100%;
            margin-bottom: 32px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .card-title {
            @extend .titleBold;
            font-weight: 800;
            margin-bottom: 24px;
            max-width: calc(100% - 120px);

            @media (max-width: 767px) {
              max-width: 100%;
            }
          }

          .card-edit-button {
            position: absolute;
            top: 24px;
            right: 24px;
            display: flex;
            align-items: center;
            padding: 7px 16px;
            border-radius: 40px;
            background-color: $light;
            font-style: normal;
            font-weight: 800;
            font-size: 12px;
            line-height: 14px;
            color: $colorAccent1;
            transition: all ease-out .2s;
            cursor: pointer;
            // Blue
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0iIzAwQkNFNyIvPjwvc3ZnPg==");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 0;

            @media (max-width: 767px) {
              position: relative;
              top: initial;
              right: initial;
              margin-top: 20px;
              width: 100%;
              justify-content: center;
              font-size: 16px;
              padding: 16px 16px;
            }

            & > svg {
              width: 16px;
              margin-right: 7px;

              & > path {
                transition: stroke ease-out .2s;
              }
            }

            &:hover {
              color: $light;
              background-size: 100%;

              & > svg > path {
                stroke: $light;
              }
            }
          }

          .recap-lines-container {
            width: 100%;
            display: flex;
            flex-direction: column;

            .recap-line-container {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;

              @media (max-width: 767px) {
                flex-direction: column;
              }

              &:last-child {
                margin-bottom: 0;
              }

              .recap-items-container {
                width: calc(50% - 32px);
                display: flex;
                flex-direction: column;

                @media (max-width: 767px) {
                  width: 100%;
                  margin-bottom: 16px;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }

                .recap-item {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 10px;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  .recap-label {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    color: $lightText;
                    margin-bottom: 4px;
                  }

                  .recap-value {
                    font-weight: 800;
                  }
                }
              }
            }
          }
        }
      }
    }



    .custom-step-container {

      .single-choice-buttons,
      .form-select {
        margin-bottom: 32px;
      }

      & + .step-form-container {
        .form-select {
          margin-bottom: 24px;
        }
      }

      .single-choice-buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          color: #ffffff;
          margin: 0 20px;
          max-width: max-content;
          border: 2px solid $colorAccent1;
          background-color: $colorAccent1;

          &:hover {
            color: $colorAccent1
          }

          &.selected {
            color: $colorAccent1;
            background-color: #ffffff;
            text-decoration: underline;
            border: 2px solid #ffffff;
          }
        }
      }
    }
  }

  .dropzone-wrapper {
    width: 100%;
    border: dashed $colorAccent1 2px;
    border-radius: 10px;
    background-color: $light;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0 48px;
  }

  .dropzone {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropzone-label {
    width: 100%;
    height: 100px;
    text-align: center;
    color: $colorAccent1;
    font-size: 24px;
    font-weight: bold;
    line-height: 100px;

    &:hover {
      cursor: pointer;
    }
  }

  .file-list-wrapper {
    padding: 25px 56px;
    width: 100%;
  }

  .file-list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .file-item {
    width: 25%;
    height: 63px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .file-item-img-container {
    position: relative;
    width: 100%;
    height: 100%;
    border: solid #a19b9b 1px;
    border-radius: 16px;

    &:hover {
      cursor: pointer;


      .file-item-delete-btn {
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        backdrop-filter: blur(6px);
        background-color: transparent;
      }
    }
  }

  .file-item-pdf {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .file-item-pdf-icon {
    width: 25px;
    height: 25px;
  }

  .file-item-img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
    transition: backdrop-filter .3s ease-in-out;
  }

  .file-item-delete-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    opacity: 0;
    border-radius: 16px;
    visibility: hidden;
    backdrop-filter: blur(0);
    transition: all .3s ease-in-out;
  }

  .file-item-delete-btn-icon {
    width: 24px;
    height: 24px;
  }

  .file-label {
    width: 100%;
    background-color: $colorAccent3;
    border-radius: 26px;
    color: $dark;
    padding: 11px 0;
    font-size: 20px;
    margin-top: 12px;
    display: block;
    text-align: center;
    font-weight: bold;

    &:hover {
      cursor: pointer;
    }
  }
}
